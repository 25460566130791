import React from "react"
import BadgingTitle from "../../components/Badging/BadgingTitle"
import tw from "twin.macro"
import BadgingCards from "../../components/Badging/BadgingCards"
import BadgingAccordion from "../../components/Badging/BadgingAccordion"
import BadgingQuestions from "../../components/Badging/BadgingQuestions"
import { graphql } from "gatsby"
import { BadgingContentTemplatePageData, TemplateProps } from "../templates"
import BadgingInstructions from "../../components/Badging/BadgingInstructions"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { SEO } from "../../components/SEO"
import { Image } from "../../components/Image"

const badging: React.FC = ({
  data,
  location,
}: TemplateProps<BadgingContentTemplatePageData>) => {
  const { title, content, featuredImage, template } = data.wpPage
  const {
    accordionSection,
    badgingCardSection,
    badgingHero,
    instructionsSection,
    questionsSection,
  } = template.badgingPage
  const image = getImage(featuredImage?.node?.localFile)

  return (
    <div className="page-content">
      <SEO url={location.href} title={title} />
      <section>
        <Image
          css={[tw`h-80`]}
          src={image}
          publicURL={featuredImage?.node?.localFile?.publicURL}
          objectPosition="100% 100%"
          alt=""
          role="presentation"
        />
        <div
          css={[
            {
              backgroundImage: "url('airport-interior.png')",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
            },
          ]}
        >
          <BadgingTitle title={title} content={content} />
          <BadgingInstructions {...instructionsSection} />
          <BadgingCards {...badgingCardSection} />
          <BadgingAccordion {...accordionSection} />
          <BadgingQuestions {...questionsSection} />
        </div>
      </section>
    </div>
  )
}

export default badging

export const query = graphql`
  query ($id: String) {
    wpPage(id: { eq: $id }) {
      title
      content
      featuredImage {
        node {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData
            }
          }
        }
      }
      template {
        ... on WpTemplate_Badging {
          templateName
          badgingPage {
            accordionSection {
              accordion {
                downloadFile {
                  localFile {
                    publicURL
                  }
                }
                description
                title
              }
              bookingLink {
                target
                title
                url
              }
              description
              details
              headline
            }
            badgingCardSection {
              cardLinks {
                backgroundImage {
                  localFile {
                    publicURL
                    childImageSharp {
                      gatsbyImageData
                    }
                  }
                }
                icon {
                  localFile {
                    publicURL
                  }
                }
                link {
                  target
                  title
                  url
                }
              }
              headline
            }
            badgingHero {
              description
              headline
            }
            instructionsSection {
              description
              headline
            }
            questionsSection {
              contactInfo
              headline
            }
          }
        }
      }
    }
  }
`
