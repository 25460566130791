import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import { BadgingQuestionsProps } from "./Badging.d"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

const BadgingQuestions: React.FC<BadgingQuestionsProps> = ({
  headline,
  contactInfo,
}) => {
  const [text, setText] = useState({ headline, contactInfo })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, contactInfo })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline, contactInfo])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`mt-20 px-8 md:px-0 max-w-3xl mx-auto text-center`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <p
        css={[tw`mt-5`]}
        dangerouslySetInnerHTML={{ __html: text?.contactInfo }}
      />
    </article>
  )
}

export default BadgingQuestions
