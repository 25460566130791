import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import { BadgingCardProps, BadgingCardsProps } from "./Badging.d"
import TranslateClient from "../../clients/TranslateClient"
const { getStringValuesArray, mapValuesToObject } = require("../../helpers")
import { LanguageContext } from "../../context/Language"
import { Image } from "../Image"

const Card: React.FC<BadgingCardProps> = ({ backgroundImage, icon, link }) => {
  const image = getImage(
    backgroundImage?.localFile?.childImageSharp?.gatsbyImageData
  )

  const linkText = link?.title
  const [text, setText] = useState({ linkText })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ linkText })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([linkText])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <div css={[tw`relative bg-blue-300 h-[14rem]`]}>
      <div
        css={[
          tw`absolute w-full h-full flex flex-col justify-center items-center gap-4 z-10 px-10`,
        ]}
      >
        <Image src={icon?.localFile?.publicURL} alt="" aria-hidden="true" />
        <p css={[tw`text-2xl text-white text-center`]}>{text?.linkText}</p>
      </div>
      <Image
        role="presentation"
        css={[tw`grayscale opacity-25 mix-blend-luminosity h-full w-full`]}
        src={image}
        alt=""
      />
    </div>
  )
}

const BadgingCards: React.FC<BadgingCardsProps> = ({ headline, cardLinks }) => {
  const [text, setText] = useState({ headline })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])
  return (
    <article css={[tw`px-4 md:px-0 mt-14`]}>
      <SectionHeading>{text?.headline}</SectionHeading>
      <div css={[tw`flex flex-col md:flex-row mt-8 max-w-6xl mx-auto gap-5`]}>
        {cardLinks?.map((item, index) => (
          <Card {...item} key={index} />
        ))}
      </div>
    </article>
  )
}

export default BadgingCards
