import React, { useState, useContext, useEffect } from "react"
import tw from "twin.macro"
import SectionHeading from "../SectionHeading/SectionHeading"
import { BadgingInstructionsProps } from "./Badging.d"
import TranslateClient from "../../clients/TranslateClient"
import { getStringValuesArray, mapValuesToObject } from "../../helpers"
import { LanguageContext } from "../../context/Language"

const BadgingInstructions: React.FC<BadgingInstructionsProps> = ({
  headline,
  description,
}) => {
  const [text, setText] = useState({ headline, description })
  const { language } = useContext(LanguageContext)

  useEffect(() => {
    if (language === "en") {
      setText({ headline, description })
    } else {
      ;(async () => {
        const textToTranslate = getStringValuesArray([headline, description])
        const translations = await TranslateClient.translate(
          textToTranslate,
          language
        )
        const translated = mapValuesToObject(text, translations)
        setText(translated)
      })()
    }
  }, [language])

  return (
    <article css={[tw`px-8 md:px-0 mt-14`]}>
      {headline && <SectionHeading>{text?.headline}</SectionHeading>}
      {description && (
        <p
          css={[tw`max-w-3xl mx-auto mt-5 mb-20`]}
          dangerouslySetInnerHTML={{ __html: text?.description }}
        />
      )}
    </article>
  )
}

export default BadgingInstructions
